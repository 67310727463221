@import url('https://fonts.googleapis.com/css?family=Dongle');

/* disabling bootstrap box-sizing override */
* {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
*:before,
*:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

body {
    background-color: #000;
}

table {
    margin: auto;
    width: 90%;
    font-size: 2rem;
}
td {
    padding: 0rem 2rem;
    border-color: #222;
    border: dashed;
}

.App {
    text-align: center;
    position: relative;

    font-family: Dongle;
    font-weight: 400;
    color: white;
    margin-top: 2rem;
}

.mint_button {
    font-size: 2.5rem;
    background-color: #89344B;
    border-color: #89344B;
    margin-left: 1rem;
}

.mint_button:hover {
    background-color: #972d49;
    border-color: #89344B;
}

.fundButton {
    font-size: 1.5rem;
}
